import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Ehrenfeld: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Ehrenfeld,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Ehrenfeld"
          d="M460,590l-8,10c-2.2,8.4-6.2,25.5-8,34l-24-22l-12,10l-41-14l-8-12l-10-2l22-12l-6-6l10-4l21-38l20,14l-4,6 l26,14L460,590z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 384.46 591.5975)"
          textRendering="geometricPrecision"
        >
          Ehrenfeld
        </text>
      </g>
    </>
  );
});
